@font-face {
  font-family: MuseoSans;
  src: url("./assets//fonts/MuseoSans-300.woff");
}

body {
  margin: 0;
  padding: 0;
  font-family: MuseoSans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: black;
  line-height: 1.5;
  background-color: #fafafa8f !important;
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

.MuiPaper-root.MuiPopover-paper {
  max-height: 20vh;
}

.border {
  width: 100%;
  height: 10px;
  margin: 50px 0;
  background: maroon;
}

.truncate_text {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.service_truncate_text {
  width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

@media screen and (max-width: 1200px) {
  .service_truncate_text{ 
    width: 350px;
  }
}

@media screen and (max-width: 480px) {
  .service_truncate_text{ 
    width: 150px;
  }
}