polygon:hover {
  fill-opacity: 0.6;
}

.detectionSideMenu {
  display: block;
  float: left;
  color: #fff;
  font-size: 40px;
}

.listContainer {
  width: 100%;
  height: 100%;
}

.listUpperSection {
  width: 100%;
  height: 50%;
  background: pink;
}
.listLowerSection {
  width: 100%;
  height: 50%;
  background: blue;
}

#wrapper {
  position: relative;
  width: 80%;
  vertical-align: middle;
  margin: 0 auto;
}

#wrapper svg {
  top: 0;
  left: 0;
}

.img-overlay-wrap {
  position: relative;
  transition: transform 150ms ease-in-out;
  width: auto;
}

.img-overlay-wrap img {
  /* <= optional, for responsiveness */
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 75vh;
  width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.img-overlay-wrap svg {
  position: absolute;
  top: 0;
  left: 0;
}
